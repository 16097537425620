// Page where the user lands if successfully completing the form submission
import { Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const ThanksPage = () => {
  return (
    <Layout>
      <SEO title="Submission Successful" />
      <main>
        <div
          className="wrapper-body"
          style={{ backgroundColor: '#fff' }}
          id="vision"
        >
          <section
            id="intro"
            style={{
              height: '600px',
              backgroundImage: `url(${HeroImage})`,
            }}
          >
            <div className="container">
              <div
                className="col-md-12"
                style={{ paddingTop: '120px', paddingBottom: '100px' }}
              >
                <h1>Thanks</h1>
              </div>
            </div>
          </section>
          <div className="padding-topbottom-50 container main-text">
            <p>
              <strong>Submission Complete</strong>
            </p>
            <p className="padding-bottom-25">
              Thanks for your submission, someone will be in contact with you
              shortly.
            </p>
            <p className="padding-bottom-25">
              Go to <Link to="/">homepage</Link>
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ThanksPage;
